@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: "Poppins",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',  monospace;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}

.backgroundimg {
  background-image: url('/public/images/hrmsbg.jpg');
  background-repeat: repeat;
  background-attachment: scroll;
  background-position: center;
  /* height: 100vh; */
  overflow: hidden;
  /* background-color: rgba(213, 213, 213, 0.504); */
  /* background-blend-mode: soft-light; */
}

.editable-grid-cell::after {
  /* font-family: "Font Awesome 5 Free"; */
    content: "\270E";
  position: absolute;
  color: #0870D6;
  top: 0;
  right: 0;
  transform: rotateZ(90deg);
  font-size: 15px;
  font-weight: 900;
  z-index: -1; /* this will set it in background so when user click onit you will get cell-click event as if user would normally click on cell */

}

.grid-cell-centered {
  text-align: center !important;
}


.loginBgMesh{
  background-color:#99d5ff;
  /* background-image: url('../public/images/loginbackground.png'); */
  background-image:
  radial-gradient(at 82% 67%, hsla(231,71%,65%,1) 0px, transparent 50%),
  radial-gradient(at 87% 20%, hsla(360,93%,77%,1) 0px, transparent 50%),
  radial-gradient(at 22% 30%, hsla(264,97%,70%,1) 0px, transparent 50%),
  radial-gradient(at 85% 94%, hsla(300,79%,67%,1) 0px, transparent 50%),
  radial-gradient(at 58% 9%, hsla(9,70%,61%,1) 0px, transparent 50%),
  radial-gradient(at 32% 82%, hsla(318,65%,78%,1) 0px, transparent 50%),
  radial-gradient(at 68% 67%, hsla(73,99%,60%,1) 0px, transparent 50%);
 }

 .react-datepicker-popper {
  z-index: 9999 !important;
}

 .react-datepicker {
  z-index: 9999 !important;
}

.tawk-branding{
  visibility: hidden !important;
}